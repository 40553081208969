import * as React from "react";

import styles from "./hero.module.css";

export const Hero = ({ title, photo}) => {
  return (
    <div style={{ backgroundImage: `url(${photo})`}} className={styles.container}>
      <nav>

        <p></p>
        <div>
          <a href="/#about-us">O nas</a>
          <a href="/#apartments">Apartamenty</a>
          <a href="/#contact">Kontakt</a>
        </div>
      </nav>

      <div className={styles.content}>
        <div>
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;

